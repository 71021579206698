import React, { useState } from 'react'
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";

const FAQ = () => {
    const [faqs, setFaqs] = useState([
        {
            question: "No cure. No pay",
            answer:
                "If POX cannot find any vulnerabilities or solve the task, no payment is required.",
            open: true
        },
        {
            question: "Certified Pentester and malware analyzer.",
            answer: "Proof of certification can be provided during consultation.",
            open: false
        },
        
    ]);

    const toggleFAQ = (index) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div className='faq' id='FAQs' >
            <div style={{ textAlign: "center", color: "gold" , margin:"15px" }}>
                <h1>FAQS</h1>

            </div>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <div
                        className={"faq " + (faq.open ? "open" : "")}
                        key={index}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">{faq.question}</div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default FAQ