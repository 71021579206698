import React from 'react'
import logo from "../images/POX.png"
import { Link } from "react-router-dom"
import {AiOutlineBars} from "react-icons/ai"
const Navbar = () => {
    return (
        <div >
            <nav className="navbar custom_navbar navbar-expand-lg navbar-light ">
                <div className="container-fluid">
                    <Link to="/">
                    <img src={logo} alt="" width="80" height="70" />
                    </Link>
                    <div>

                        <span className="navbar-brand" style={{ color: "white", fontSize: "25px", fontWeight: "bold" }} href="/">POX</span>
                        <span className="navbar-brand" style={{ color: "white", fontSize: "15px" }} href="#">Fighting Cyber Crime</span>
                    </div>
                    <button class="navbar-toggler" style={{ color:"white"}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
         
                        <span><AiOutlineBars/></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navbar_ul ">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">FAQ</Link>
                            </li>
                      
                            <li className="nav-item" >
                                <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

        </div>
    )
}

export default Navbar