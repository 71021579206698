import React from 'react'
import FAQ from './FAQ'
import Footer from './Footer'
import Main from './Main'
import Navbar from './Navbar'

const Home = () => {
    return (
        <div>
            <Navbar />
            <Main />
            <FAQ />
            <Footer />

        </div>
    )
}

export default Home