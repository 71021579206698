import logo from './logo.svg';
import './App.css';
import Home from './component/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PrivacyPolicy from './component/PrivacyPolicy';


function App() {
  return (
    <div className="App">
      <div className='container'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>

      </div>
    </div>
  );
}

export default App;
