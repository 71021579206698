import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <p>
               <b> &copy; Copyright All Rights Reserved POX</b>
            </p>
        </div>
    )
}

export default Footer