import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// import { toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';

const Main = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ak9x14d', 'template_n51d51m', form.current, 'L6P661WjGoNfyJpny')
            .then((result) => {
                console.log(result);
                toast('Thank You For Contacting Us !',
                    {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                console.log(result.text);
                e.target.reset()
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className='main'>
            <div className='services'>
                <div>
                    <Toaster />
                    <h1 style={{ color: "gold", fontWeight: "bold", textAlign: "center" }}>Services</h1>
                </div>
                <div style={{margin:"30px 0"}}>
                    <p >Performing Pentest on following topics:</p>
                    <ul>
                        <li>
                            IoT Sevice Pentesting
                        </li>
                        <li>
                            Cloud Pentesting, AWS/Azure
                        </li>
                        <li>
                            AD Infrastructure Pentesting
                        </li>
                        <li>
                            Web Application Pentesting
                        </li>
                        <li>
                            Network Pentesting
                        </li>
                        <li>
                            Analyzing Malware
                        </li>
                    </ul>


                </div>
            </div>
            <div className='contact'>
                <div>
                    <h1 style={{ color: "gold", fontWeight: "bold", textAlign: "center" }}>Contact</h1>
                </div>
                <div>
                    <div>
                        {/* <form >

                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label" style={{ color: "white" }}>Email address</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" style={{ background: "black", color: "white", border: "none" }} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{ color: "white" }}>Example textarea</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} style={{ background: "black", color: "white", border: "none", resize: "none" }} />
                            </div>
                            <div className="mb-3 mt-3">
                                <button type="button" class="btn" style={{background:"gold "}}>Submit</button>
                            </div>
                        </form> */}

                        <form ref={form} onSubmit={sendEmail}>
                            <div className="mb-3">

                                <label htmlFor="exampleFormControlInput1" className="form-label" style={{ color: "white" }}>Name</label>
                                <input required type="text" name="user_name" className="form-control" id="exampleFormControlInput1" placeholder="John" style={{ background: "black", color: "white", border: "none" }} />
                            </div>
                            <div className="mb-3">

                                <label htmlFor="exampleFormControlInput2" className="form-label" style={{ color: "white" }}>Email</label>
                                <input required type="email" name="user_email" className="form-control" id="exampleFormControlInput2" placeholder="name@example.com" style={{ background: "black", color: "white", border: "none" }} />
                            </div>

                            <div className="mb-3">

                                <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{ color: "white" }}>Message</label>
                                <textarea required name="message" className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} style={{ background: "black", color: "white", border: "none", resize: "none" }} />
                            </div>
                            <div className="mb-3 mt-3">
                                {/* <button type="button" class="btn" style={{ background: "gold " }}>Submit</button> */}
                                <input type="submit" className="btn" style={{ background: "gold" }} value="Send" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main