import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

const PrivacyPolicy = () => {
    return (
        <div >
            <Navbar />
            <h1 style={{margin:"20px 0" , color:"gold"}}>Privacy Policy</h1>
            <div className='pp'>

                <p>
                    Purpose: Penetration testing of the organization's/clients information systems to identify vulnerabilities and risks.
                </p>

                <p>
                    Scope: All or parts of the information systems within the organization, including but not limited to networks, servers, databases, computers, web applications and IoT devices.

                </p>
                <p>
                    Testing Requirements: Penetration testing will be performed by authorized personnel using industry-standard tools and techniques. The organization/client needs to specify if the test is a dark test or transparent test.

                </p>
                <p>
                    Authorization: Authorization must include the specific systems and applications to be tested and the time period during which testing will occur. Prior to conducting penetration testing, written authorization must be obtained from the appropriate authority, such as the Chief Information Security Officer or the Head of IT.
                </p>
                <p>
                    Notification: All personnel who may be affected by the testing, such as system administrators or network engineers, must be notified in advance of the testing.
                </p>
                <p>
                    Rules of Engagement: The rules of engagement must be clearly defined and communicated to all parties involved in the testing. This includes the scope of the testing, the types of testing to be performed, and any limitations or exclusions.

                </p>
                <p>
                    Reporting: A final report will be delivered to the authorized authority, detailing clearly the results and the necessary actions required further, including any vulnerabilities identified and recommendations for remediation.

                </p>
                <p>
                    Retesting: After remediation of identified vulnerabilities, retesting must be performed to ensure that the vulnerabilities have been successfully addressed.

                </p>

                <p>
                    Confidentiality: All information obtained during penetration testing must be treated as confidential and may only be disclosed to authorized personnel with a need to know.

                </p>
                <p>
                    Compliance: This policy will change according to new methods and technologies and will be updated on a regular basis to ensure compliance with industry standards and best practices.

                </p>

                <p>
                    NDA: A NDA document will be written beforehand to ensure both parties confidentiality and secure that all data gathered remain confidential

                </p>
                <p>
                    To follow the regulation (EU) 2016/679 of the European Parliament (the General Data Protection Regulation (‘GDPR’)) and the Data Protection Act 2018 we are obliged to inform you about the information about your personal data, how it is being gathered, stored and used.
                </p>

                <p>
                    All information gathered will be stored locally and deleted four (4) weeks after the mission is signed completed. Therefore, it is not possible to retrieve customer data after this time.
                    Your personal data that will be stored temporarily during the mission will depend on the task given, this will be clarified upon agreement and you are at any time allowed to access the data or having the data deleted.
                </p>
            </div>

            <Footer/>
        </div>
    )
}

export default PrivacyPolicy